/* eslint-disable react/jsx-newline */
import React from "react";
import Input, { InputProps, NumberInputProps } from "../Input/Input";
import { Label } from "../Label/Label";
import { Select, SelectProps } from "../Select/Select";
import omit from "lodash.omit";
import { RichTextEditorProps } from "../RichTextEditor/RichTextEditor";
import { styled } from "../../styles";
import { UploadInputProps } from "../FileUploader/UploadInput";
import { DatePickerProps } from "../Datepicker/DatePicker";
import { Body, Caption } from "../Typography/Typography";

export interface BaseFormGroupProps {
  id: string;
  description?: string;
  label: string | null;
  showLabelOnMobileOnly?: boolean;
}

export interface BaseInputProps {
  required?: boolean;
  readOnly?: boolean;
}

export type TelInputProps = InputProps & {
  defaultCountry: string;
};

export type InputValueDisplay = InputProps & {
  value: string;
};

export type FormGroupInputProps =
  | {
      type: "select";
      inputProps: SelectProps;
    }
  | {
      type: "tel";
      inputProps: TelInputProps;
    }
  | {
      type: React.HTMLInputTypeAttribute;
      inputProps: InputProps;
    }
  | {
      type: "number";
      inputProps: NumberInputProps;
    }
  | {
      type: "richtext";
      inputProps: RichTextEditorProps;
    }
  | {
      type: "file";
      inputProps: UploadInputProps;
    }
  | {
      type: "date";
      inputProps: DatePickerProps;
    }
  | {
      type: "value_display";
      inputProps: InputValueDisplay;
    };

export type FormGroupProps = BaseFormGroupProps & FormGroupInputProps;

const RequiredText = styled("span", {
  color: "red",
});

const FormGroupContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
});

const LabelContainer = styled("div", {
  display: "grid",
  gridTemplateColumns: "1fr",
  marginBottom: "$2",
  rowGap: "$1",
});

export const FormGroup: React.FC<FormGroupProps> = ({
  description,
  label,
  id,
  type,
  inputProps,
  showLabelOnMobileOnly = false,
}) => {
  if (type === "value_display") {
    return (
      <FormGroupContainer
        css={{
          height: "100%",
        }}
      >
        {label && (
          <LabelContainer
            css={{
              marginBottom: "$1",
            }}
          >
            <Label as="span">{label}</Label>
          </LabelContainer>
        )}

        <div
          style={{
            alignItems: "center",
            display: "flex",
            height: "100%",
          }}
        >
          <Caption
            css={{
              fontSize: "14px",
            }}
          >
            {(inputProps as InputValueDisplay).value}
          </Caption>
        </div>
      </FormGroupContainer>
    );
  }

  const required = inputProps.required;

  return (
    <FormGroupContainer>
      {(label || description) && (
        <LabelContainer
          css={{
            display: showLabelOnMobileOnly ? "flex" : "inherit",
            flexDirection: "column",

            "@bp2a": {
              display: showLabelOnMobileOnly ? "none" : "flex",
            },
          }}
        >
          <Label htmlFor={id}>
            {label} {required && <RequiredText>*</RequiredText>}
          </Label>

          {description && (
            <Body
              size="small"
              css={{
                marginBottom: "0",
                marginTop: "0",
              }}
            >
              &#9432; {description}
            </Body>
          )}
        </LabelContainer>
      )}

      <div>
        {type === "select" ? (
          <Select name={id} {...(omit(inputProps, ["ref"]) as SelectProps)} />
        ) : (
          <Input name={id} type={type} {...(inputProps as InputProps)} />
        )}
      </div>
    </FormGroupContainer>
  );
};
