import React, { PropsWithChildren } from "react";
import { css, styled } from "../../styles";
import type * as Stitches from "@stitches/react";

const baseHeadingStyles = css({
  color: "$textActive",
  fontFamily: "$headings",
  fontWeight: 600,
});

const baseTextVariants = {
  centered: {
    true: {
      textAlign: "center",
    },
  },

  textAlign: {
    left: {
      textAlign: "right",
    },
    right: {
      textAlign: "right",
    },
  },

  noMargin: {
    true: {
      margin: 0,
    },
  },
};

type BaseTextProps = {
  centered?: boolean;
};

export const BaseHeading = styled("h1", {
  color: "$textActive",
  fontFamily: "$headings",
  fontWeight: 600,

  variants: {
    type: {
      h1: {
        fontSize: "40px",
        lineHeight: "64px",
      },
      h2: {
        fontSize: "32px",
        lineHeight: "1.5",
      },
      h3: {
        fontSize: "24px",
        lineHeight: "1.5",
      },
      h4: {
        fontSize: "18px",
        lineHeight: "1.5",
      },
      h5: {
        fontSize: "24px",
        lineHeight: "133%",
      },
      h6: {
        fontSize: "18px",
        lineHeight: "133%",
      },
    },

    ...baseTextVariants,
  },
});

export type HeadingProps = BaseTextProps & {
  css?: Stitches.CSS;
  type: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  title?: string;
};

export const Heading = ({
  type,
  children,
  centered,
  ...rest
}: PropsWithChildren<HeadingProps>) => (
  <BaseHeading type={type} as={type} centered={centered} {...rest}>
    {children}
  </BaseHeading>
);

export const Title = styled("h6", baseHeadingStyles, {
  fontFamily: "$body",
  fontSize: "16px",
  lineHeight: "19px",

  variants: {
    ...baseTextVariants,
  },
});

export const Caption = styled("div", {
  color: "$textLight",
  fontFamily: "$body",
  fontSize: "13px",
  fontWeight: 600,
  lineHeight: "18px",

  variants: {
    size: {
      small: {
        fontSize: "12px",
        fontWeight: "500",
        lineHeight: "16px",
      },
    },

    ...baseTextVariants,
  },
});

export const Body = styled("p", {
  fontFamily: "$body",
  fontSize: "14px",
  lineHeight: "171%",

  variants: {
    ...baseTextVariants,

    size: {
      small: {
        fontSize: "12px",
        lineHeight: "18px",
      },
    },
  },
});
